import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import Glide from '@glidejs/glide'
import SweetScroll from 'sweet-scroll'
import 'alpinejs'

require.context('../images', true)
import '../css/application.scss'

Rails.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', function () {
  new SweetScroll()

  if (document.querySelector('#activities_slider')) {
    const glideSlide = new Glide('#activities_slider', {
      type: 'carousel',
      perView: 5,
      focusAt: 'center',
      gap: 32,
      hoverpause: true,
      breakpoints: {
        1024: {
          perView: 3,
        },
        600: {
          perView: 1,
        },
      },
    })

    glideSlide.mount()

    let currentSlide = document.querySelector('.glide__slide--active')
    let slideTitle = currentSlide.getAttribute('data-slide-title')
    const activityTitle = document.querySelector('#activities_title')

    activityTitle.innerText = slideTitle

    glideSlide.on('run.after', function () {
      currentSlide = document.querySelector('.glide__slide--active')
      slideTitle = currentSlide.getAttribute('data-slide-title')
      activityTitle.innerText = slideTitle
    })
  }

  if (document.querySelector('#team_slider')) {
    const glideSlide = new Glide('#team_slider', {
      type: 'carousel',
      perView: 3,
      gap: 32,
      hoverpause: true,
      breakpoints: {
        1024: {
          perView: 2,
        },
        640: {
          perView: 1,
        },
      },
      rewind: true,
    })

    glideSlide.mount()
  }

  function fixedHeader() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const navigationEl = document.querySelector('#navigation')
    if (scrollTop > 0) navigationEl.classList.add('is-stuck')
    else navigationEl.classList.remove('is-stuck')
  }

  fixedHeader()
  document.addEventListener('scroll', () => fixedHeader())
})
